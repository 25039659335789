.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
table, tr, td {
    border: none;
}
.ant-input-number-handler-wrap{
    display: none;
}
.home-site-layout-content {
    max-width: 100%;
    min-height: 90vh;
    margin: 0 auto;
    background: #ffffff;
}

.index-menu {
    border-bottom: none;
    background: #d5e6cd;
    color: #ffffff;
}

.index-menu .ant-menu-title-content {
    color: #274b10;
}

.indexMenu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
    border-bottom: 2px solid #274b10;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #274b10;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #28a745;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #28a745;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background: #28a745;
}

.btn-danger {
    color: #fff;
    background-color: rgb(189, 35, 35);
    border-color: #000;
}

.btn-danger:focus {
    color: #fff;
    background-color: rgba(189, 35, 35, 0.1);
    border-color: #000;
}

.btn-danger:hover {
    color: #fff;
    background-color: rgba(189, 35, 35, 0.5);
    border-color: #000;
}

.btn-out-line {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: #fff;
}

.btn-out-line:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border-color: #fff;
}

.btn-out-line:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border-color: #fff;
}

.success:hover {
    background-color: #148632;
    border-color: #148632;
}
/*font blink effect*/
.blink {
    animation: blinker 1s linear infinite;
    color: #9a9a9a;
    font-size: 12px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
